import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";
import './style.css'

import Home from "./components/Home" 
import Success from "./components/Success" 
import Support from "./components/Support" 
import ReportThisEmail from "./components/ReportThisEmail" 

const App = () => {
  document.title = 'Plugin';
  let routes = useRoutes([
    { path: "/", element: <ReportThisEmail /> },
    { path: "/success", element: <Success /> },
    { path: "report-this-email", element: <ReportThisEmail /> },
    { path: "support", element: <Support /> },
    // ...
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;