import {
  Link,
} from "react-router-dom";
function Support() { 
    return ( 
       <div> 
            <center><h1> <Link  className="reportThisEmail" to='/'>Back</Link></h1>
            <p>Coming soon</p></center> 
      </div> 
    ); 
} 
export default Support;