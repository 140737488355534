import React from "react"; 
import {
  Link, useNavigate
} from "react-router-dom";



function Success() { 
    const navigate = useNavigate();
    function closeOfficeBox()
      {
        if(window.Office.context)
            window.Office.context.ui.closeContainer();
      }
    setTimeout(function() {
      closeOfficeBox();
    }, 5000);
    return ( 
       <div> 
            <div class="sc-CtfFt fCxIoL"><div class="sc-laTMn fbNykB"><header class="sc-fjmCvl kYBChq"><h4>Your submission has been reported.</h4></header><div class="sc-dymIpo dZhGMm">
            <button  onClick={() => closeOfficeBox()} class="sc-cpmLhU btVlSo sc-fhYwyz bQKYwH">Close</button></div></div><div class="sc-dTdPqK iIXqXR"><div class="sc-fzsDOv crGvCx">Extension</div></div><div class="sc-eMigcr chiOUu"></div></div>
      </div> 
    ); 
} 
export default Success;